import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import AuthLayout from "../layouts/AuthLayout.vue";
import AppLayout from "../layouts/AppLayout.vue";

import RouteViewComponent from "../layouts/RouterBypass.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "dashboard" },
  },
  {
    name: "admin",
    path: "/",
    component: AppLayout,
    redirect: { name: "dashboard" },
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("../pages/workouts/Feed.vue"),
      },
      {
        name: "routes",
        path: "routes",
        component: () => import("../pages/routes/Route.vue"),
      },
      {
        name: "workouts",
        path: "workouts",
        children: [
          {
            name: "list_workouts",
            path: "",
            component: () => import("../pages/workouts/WorkoutList.vue"),
          },
          {
            name: "upload_workouts",
            path: "upload",
            component: () => import("../pages/workouts/WorkoutUpload.vue"),
          },
          {
            name: "workout_item",
            path: "workouts/:id",
            component: () => import("../pages/workouts/WorkoutItem.vue"), 
          },
          {
            name: "workout_public_item",
            path: "public/workouts/:id",
            component: () => import("../pages/workouts/WorkoutPublicItem.vue"), 
          }
        ],
      },
      {
        name: "preferences",
        path: "preferences",
        component: () => import("../pages/preferences/Preferences.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "logout",
        path: "logout",
        component: () => import("../pages/auth/Logout.vue"),
      },
      {
        name: "login",
        path: "login",
        component: () => import("../pages/auth/Login.vue"),
      },
      {
        name: "signup",
        path: "signup",
        component: () => import("../pages/auth/Signup.vue"),
      },
      {
        name: "recover-password",
        path: "recover-password",
        component: () => import("../pages/auth/RecoverPassword.vue"),
      },
      {
        name: "recover-password-email",
        path: "recover-password-email",
        component: () => import("../pages/auth/CheckTheEmail.vue"),
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
  routes,
});

export default router;
